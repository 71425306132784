import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { load_user } from "../redux/actions/auth";
import Link from "next/link";
import { Formik } from "formik";
import { Form, Row, Col, Button } from "react-bootstrap";
import * as Yup from "yup";
import { LOGIN_SUCCESS, WS_CONNECT } from "../redux/types";
import { requestLogin } from "../api/auth";

export default function LoginForm() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const router = useRouter();
  if (isAuthenticated) router.push("/");

  const schema = Yup.object().shape({
    email: Yup.string()
      .email("Valid e-mail is required")
      .required("E-mail is required"),
    password: Yup.string().required("Password is required"),
  });

  return (
    <>
      <div className="row justify-content-sm-center h-100 mt-5">
        <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
          <div className="card shadow-lg">
            <div className="card-body p-5">
              <h1 className="fs-4 card-title fw-bold mb-4">Login</h1>
              <Formik
                validationSchema={schema}
                onSubmit={async (values, { setFieldError }) => {
                  try {
                    const res = await requestLogin(values);
                    if (res.status === 200) {
                      dispatch({
                        type: LOGIN_SUCCESS,
                      });
                      dispatch({
                        type: WS_CONNECT
                      });
                      dispatch(load_user());
                    } else {
                      setFieldError(
                        "password",
                        "No active account found with the given credentials"
                      );
                    }
                  } catch (err) {
                    console.log(err);
                  }
                }}
                initialValues={{
                  email: "",
                  password: "",
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isSubmitting,
                  errors,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <Form.Group md="4" controlId="validationFormikEmail">
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="E-mail"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.email && errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group md="4" controlId="validationFormikPassword">
                        <Form.Label>Password</Form.Label>
                        <Link href="/forgot-password">
                          <a className="float-end">Forgot Password?</a>
                        </Link>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.password && errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <div className="align-items-center d-flex">
                      <Button
                        className="ms-auto"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Submitting..." : "Login"}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="card-footer py-3 border-0">
              <div className="text-center">
                Don&apos;t have an account?
                <Link href="/register">
                  <a> Create One</a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
